<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="回收员信息：">
        <el-input size="small" v-model="searchForm.nickname" placeholder="请输入回收员姓名"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="searchForm.ordersn" placeholder="请输入用户手机号"></el-input>
      </el-form-item>
      <el-form-item label="结算状态：">
        <el-select size="small" v-model="searchForm.status" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待结算" :value="2"></el-option>
          <el-option label="已结算" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算时间：">
        <el-date-picker v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="amount">
      <div>筛选后奖励金额：￥{{ amount }}</div>
      <div>
        <el-button @click="batchSettlement" type="primary" size="small" :disabled="searchForm.status == 2 ? false : true">批量结算</el-button>
        请结算状态选择待结算，则可进行批量结算
      </div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="ordersn" label="订单编号" align="center"></el-table-column>
      <el-table-column label="下单用户信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.orders_avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column label="回收员信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.name" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="回收员电话" align="center"></el-table-column>
      <el-table-column label="用户身份" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? '普通用户' : row.identity_type == 2 ? '推广员' : row.identity_type == 3 ? '区域管理员' : row.identity_type == 4 ? '专职回收员' : '兼职回收员' }}
        </template>
      </el-table-column>
      <el-table-column prop="order_price" label="用户订单金额" align="center"></el-table-column>
      <el-table-column prop="recycle_price" label="回收站支付金额" align="center"></el-table-column>
      <el-table-column prop="amount" label="应得/奖励金额" align="center"></el-table-column>
      <el-table-column label="结算状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 2 ? '待结算' : '已结算' }}
        </template>
      </el-table-column>
      <el-table-column prop="recorded_time" label="入账时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button v-if="row.status == 2" type="text" @click="settlement(row.id)">结算</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        ordersn: '',
        status: '',
        start_time: '',
        end_time: '',
      },
      list: [],
      total_number: 0,
      amount: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        identity_type: '',
        ordersn: '',
        status: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    settlement(id) {
      this.$axios.post(this.$api.O2O.finance.settlementL, { id: id }).then(res => {
        if (res.code == 0) {
          this.$message.success('结算成功');
          this.cancelSearch();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    batchSettlement() {
      this.$axios.post(this.$api.O2O.finance.batchSettlement).then(res => {
        if (res.code == 0) {
          this.$message.success('结算成功');
          this.cancelSearch();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.O2O.finance.recylerList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.recorded_time = item.recorded_time ? getDateformat(item.recorded_time) : '--'
            item.order_price = '￥' + item.order_price;
            item.amount = '￥' + item.amount;
            item.recycle_price = '￥' + item.recycle_price;
          });
          this.list = list;
          this.amount = res.result.amount;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .amount {
    display: flex;
    align-items: center;
    margin: 20px 0;
    div:nth-child(2) {
      margin-left: 20px;
      display: flex;
      align-items: center;
      .el-button {
        margin-right: 20px;
      }
    }
  }
}
</style>
